import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'
import Alert from '@myob/myob-widgets/lib/components/Alert/Alert'
import PropTypes from 'prop-types'

@inject('store')
@observer
class AlertMessage extends Component {
    render() {
        if (this.props.store.alert != null) {
            const alertBody = this.props.store.alert
            const alertType = alertBody.alertType ? alertBody.alertType : 'danger'
            const fieldValidationErrors = alertBody.fieldErrors == null ? null
                // eslint-disable-next-line react/no-array-index-key
                : (<ul>{alertBody.fieldErrors.map((e, idx) => (<li key={idx}>{e}</li>))}</ul>)
            return (
                <Alert type={alertType}>
                    {alertBody.message}
                    {fieldValidationErrors}
                </Alert>
            )
        }
        return null
    }
}

AlertMessage.propTypes = {
    store: PropTypes.exact({
        alert: PropTypes.string.isRequired,
    }).isRequired,
}

export default AlertMessage

import React, {Component, lazy, Suspense} from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Provider} from 'mobx-react'
import {Redirect} from 'react-router'
import PropTypes from 'prop-types'
import AppStore from './store/AppStore'
import AlertMessage from './components/AlertMessage'

import '@myob/myob-styles/dist/styles/myob-clean.css'
import '../resources/static/main.css'

/* istanbul ignore next */
export const Card = lazy(() => import('./pages/Card'))
/* istanbul ignore next */
export const Cart = lazy(() => import('./pages/Cart'))
/* istanbul ignore next */
export const Payment = lazy(() => import('./pages/Payment'))
/* istanbul ignore next */
export const Success = lazy(() => import('./pages/Success'))
/* istanbul ignore next */
export const MerchantDetail = lazy(() => import('./pages/MerchantDetail'))
/* istanbul ignore next */
export const CaptureCardDetail = lazy(() => import('./pages/CaptureCardDetail'))
/* istanbul ignore next */
export const CardList = lazy(() => import('./pages/CardList'))
/* istanbul ignore next */
export const CaptureCardSuccess = lazy(() => import('./pages/CaptureCardSuccess'))
/* istanbul ignore next */
export const PaymentFailed = lazy(() => import('./pages/PaymentFailed'))
/* istanbul ignore next */
export const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'))
/* istanbul ignore next */
export const CaptureCardFailed = lazy(() => import('./pages/CaptureCardFailed'))
/* istanbul ignore next */
export const PaymentDetail = lazy(() => import('./pages/PaymentDetail'))
/* istanbul ignore next */
export const FatZebra = lazy(() => import('./pages/FatZebra'))
/* istanbul ignore next */
export const FatZebraCallback = lazy(() => import('./pages/FatZebraCallback'))

// eslint-disable-next-line react/prefer-stateless-function
class Routes extends Component {
    render() {
        return (
            <Provider store={this.props.store}>
                <Router>
                    <Suspense fallback={<div>Loading...</div>}>
                        <div style={{height: '100%'}}>
                            <AlertMessage/>
                            <h1>Merchant Test</h1>
                            <Switch>
                                <Route path='/cart.html' component={Cart}/>
                                <Route path='/payment.html' component={Payment}/>
                                <Route path='/success.html' component={Success}/>
                                <Route path='/card.html' component={Card}/>
                                <Route path='/merchantDetail.html' component={MerchantDetail}/>
                                <Route path='/cardList.html' component={CardList}/>
                                <Route path='/captureCardDetail.html' component={CaptureCardDetail}/>
                                <Route path='/PaymentDetail.html' component={PaymentDetail}/>
                                <Route path='/PaymentFailed.html' component={PaymentFailed}/>
                                <Route path='/PaymentSuccess.html' component={PaymentSuccess}/>
                                <Route path='/captureCardSuccess.html' component={CaptureCardSuccess}/>
                                <Route path='/captureCardFailed.html' component={CaptureCardFailed}/>
                                <Route path='/fat-zebra.html' component={FatZebra}/>
                                <Route path='/fat-zebra-callback.html' component={FatZebraCallback}/>
                                <Redirect to='/cart.html'/>
                            </Switch>
                        </div>
                    </Suspense>
                </Router>
            </Provider>
        )
    }
}

export function renderRoutes() {
    const rootElement = document.getElementById('react')
    if (rootElement) {
        ReactDOM.render(<Routes store={new AppStore()}/>, rootElement)
    }
}

renderRoutes()

Routes.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    store: PropTypes.object.isRequired,
}

export default Routes
